<template>
    <el-card class="border-container" shadow="hover">
        <i class="start-icon" :class="icon"></i>
        <div class="detail">
            <p class="title">{{ title }}
                <i v-if="showTipIcon" class="itip" :class="[showTipIcon, itip]"></i>
            </p>
            <p class="describe">{{ describe }}</p>
        </div>
        <i class="el-icon-arrow-right"></i>
    </el-card>
</template>

<script>
    export default {
        name: 'borderContainer',
        props: {
            icon: {
                type: String,
                default: function() {
                    return "";
                }
            },
            title: {
                type: String,
                default: function() {
                    return "";
                }
            },
            itip: {
                type: String,
                default: function() {
                    return "";
                }
            },
            describe: {
                type: String,
                default: function() {
                    return "";
                }
            }
        },
        computed: {
            showTipIcon() {
                if (this.itip) {
                    if (this.itip === "success") {
                        return "el-icon-success";
                    } else if (this.itip === "warning") {
                        return "el-icon-warning";
                    } else if (this.itip === "info") {
                        return "el-icon-info";
                    } else if (this.itip === "error") {
                        return "el-icon-error";
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .border-container {
        cursor: pointer;
        user-select: none;

        &+.border-container {
            margin-top: 10px;
        }

        /deep/ .el-card__body {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-around;
            align-items: center;

            .start-icon {
                font-size: 40px;
                margin-right: 10px;
            }

            .detail {
                flex: 1;

                p {
                    margin: 0;

                    &+p {
                        margin-top: 10px;
                    }
                }

                .title {
                    font-size: 14px;
                    font-weight: bold;

                    & .itip {
                        &.success {
                            color: #67C23A;
                        }

                        &.warning {
                            color: #E6A23C;
                        }

                        &.info {
                            color: #909399;
                        }

                        &.error {
                            color: #F56C6C;
                        }
                    }
                }

                .describe {
                    color: #777;
                    font-size: 12px;
                }
            }
        }
    }
</style>
